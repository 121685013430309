import {_, Component, React, className} from '@symbolic/react-app'; //eslint-disable-line
import ShareButton from 'components/share-link-button/share-link-button';
import ReferenceIcons from 'components/reference-icons/reference-icons';
import BrandIcons from '../../../brand-icons/brand-icons';
import BodyText from 'components/body-text';
import lib from 'components';
import './slideshow-document.scss';

class SlideshowDocument extends Component {
  state = {activeIndex: 0}

  componentDidMount() {
    this.setIndexFromSubtopicId();

    document.onkeydown = (event) => {
      if (lib.event.keyPressed(event, 'left')) this.handleIndexChange({direction: 'left'});
      if (lib.event.keyPressed(event, 'right')) this.handleIndexChange({direction: 'right'});
    };
  }

  componentWillUnmount() {
    document.onkeydown = null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.subtopicId && this.props.subtopicId !== prevProps.subtopicId) {
      this.setIndexFromSubtopicId();
    }
  }

  setIndexFromSubtopicId() {
    if (this.props.subtopicId) {
      var activeIndex = _.findIndex(this.props.document.documentSubtopics, {id: this.props.subtopicId});

      if (activeIndex !== -1) this.setState({activeIndex});
    }
  }

  handleIndexChange = ({direction}) => {
    var maxIndex = _.get(this.props, `document.documentSubtopics.length`) - 1;
    var index = lib.number.cycleIndex({direction, min: 0, max: maxIndex, input: this.state.activeIndex});

    this.setState({activeIndex: index});
  }

  render() {
    var {document, setClickedMedium, setHoveredMedium, allowSharing, match} = this.props;
    var {handleIndexChange} = this;
    var {documentSubtopics} = document;
    var activeIndex = _.get(this.props, 'activeIndex', this.state.activeIndex);

    var activeMedium = _.find(documentSubtopics[activeIndex].media, ({subjectType}) => !_.includes(subjectType, 'reference'));
    var referenceMedia = _.filter(documentSubtopics[activeIndex].media, ({subjectType}) => _.includes(subjectType, 'reference'));

    return(
      <div {...className(['slideshow', activeMedium && `type-${activeMedium.type}`])}>
        <div className='slideshow-media-container'>
          {documentSubtopics.length > 0 && _.map(documentSubtopics, ({media}, index) => (
            media.length > 0 && <Medium key={media[0].id} {...{media, index, activeIndex, setClickedMedium, setHoveredMedium}}/>
          ))}
        </div>
        {documentSubtopics.length > 0 && (
          <div{...className(['slideshow-tray', `count-${documentSubtopics.length}`, !_.isEmpty(referenceMedia) && 'bottom-padded'])}>
            <div className='slideshow-caption'>
              <div className='document-title'>{document.title}</div>
              <BrandIcons {...{document}}/>
              {allowSharing && <ShareButton {...{match}}/>}
              <div className='subtopic-body'><BodyText text={documentSubtopics[activeIndex].body}/></div>
            </div>
            <div className='slideshow-tray-controls'>
              <div className='slideshow-index'>{`${activeIndex + 1} / ${documentSubtopics.length}`}</div>
              {_.map([
                  {key: 'left', link: 'left-arrow.png', onClick: () => handleIndexChange({direction: 'left'})},
                  {key: 'right', link: 'right-arrow.png', onClick: () => handleIndexChange({direction: 'right'})}
                ], ({key, link, onClick}) => (
                <div className={`slideshow-button ${key}-button-container`} {...{key, onClick}}>
                  <img alt='' className={`${key}-button`} src={`https://henrybuilt-cdn.s3-us-west-2.amazonaws.com/trade-site/icons/${link}`}/>
                </div>
              ))}
            </div>
          </div>
        )}
        {!_.isEmpty(referenceMedia) && (
          <ReferenceIcons {...{setClickedMedium, setHoveredMedium, referenceMedia, type: 'slideshow'}}/>
        )}
      </div>
    )
  }
}

var Medium = ({index, activeIndex, media}) => {
  var primaryMedia = _.find(media, ({type}) => !_.includes(type, 'reference'));

  var {url, type, subjectType} = primaryMedia;

  return (
    <div
      style={{backgroundImage: `url(${url})`}}
      {...className(['slideshow-medium', `type-${type}`, activeIndex === index && 'active', subjectType === 'drawing' && 'drawing'])}
      > {type === 'video' ? (
        <video className='slideshow-video-player' controls playsInline preload='none'>
          <source type='video/mp4' src={url}/>
        </video>
      ) : (
        <img src={url} className='image-for-right-clicking' alt=''/>
      )}
    </div>
  );
};

export default SlideshowDocument;
