import React from 'react'
import {Link} from 'react-router-dom';
import _ from 'lodash';
import ReactMarkdown from 'react-markdown';

function RouterLink(props) {
  return (
    _.includes(props.href, 'https://') || _.includes(props.href, 'mailto')
      ? <a href={props.href} target='_blank' rel='noopener noreferrer'>{props.children}</a>
      : <Link to={props.href}>{props.children}</Link>
  );
}

export default function BodyText({text}) {
  return (
    <ReactMarkdown
      escapeHtml={false}
      source={text}
      renderers={{link: RouterLink}}
    />
  )
}
