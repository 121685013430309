import {_, Component, React, className} from '@symbolic/react-app'; //eslint-disable-line
import InterstitialPage from 'components/pages/interstitial-page/interstitial-page';
import BrandIcons from '../../brand-icons/brand-icons';
import {Form} from 'henrybuilt-react-library';
import BodyText from 'components/body-text';
import queryString from 'query-string';
import {Link} from 'react-router-dom';
import lib from 'components';
import './index-page.scss';

class IndexPage extends Component {
  static companyMap = {
    hb: 'Henrybuilt Systems',
    po: 'Primary Objects',
    vp: 'Space Theory',
    sf: 'Symbolic Frameworks'
  }

  static topicTitles = {
    deciding: 'Figuring out if we\'re a good fit',
    'in-design': 'While we\'re in design',
    'in-production': 'While we\'re in production (and preparing for installation)',
    living: 'Living with Henrybuilt and Space Theory'
  }

  static topicsOrder = ['highlights', 'deciding', 'in-design', 'in-production', 'living', 'other'];

  state = {
    message: '',
    linkGroups: [],
    currentSearchTerm: null,
    searchTermRequested: false
  }

  componentDidMount() {
    this.setDocuments();

    this.checkForSearchTermChange = setInterval(() => {
      if (this.state.currentSearchTerm !== this.searchTerm) this.setDocuments();
    }, 100);
  }

  componentWillUnmount() {
    clearInterval(this.checkForSearchTermChange);
  }

  componentDidUpdate({companyKey: oldCompanyKey, showAllDocuments: oldShowAllDocuments}) {
    var {companyKey, showAllDocuments} = this.props;

    if (companyKey !== oldCompanyKey || showAllDocuments !== oldShowAllDocuments) {
      this.setDocuments();
    }
  }

  setDocuments = async () => {
    var {companyMap, topicTitles, topicsOrder} = IndexPage;
    var {companyKey, showAllDocuments} = this.props;
    var {searchTerm} = this;

    var {searchTerm} = lib.identifyCompanyInSearchTerm({searchTerm, extract: true}); //eslint-disable-line

    var linkGroups = [];
    var documents;
    var header = '';
    var message = '';

    if (searchTerm || (companyKey && !showAllDocuments)) {
      var {data} = await lib.api.request({
        uri: 'documents-for-search-term',
        useActualErrorMessages: true,
        body: {searchTerm, companyKey}
      });

      documents = _.get(data, 'documents');

      if (!_.isEmpty(documents)) {
        if (companyKey) {
          header = companyMap[companyKey];

          if (searchTerm) header += `: "${searchTerm}"`;
        }
        else {
          header = `"${searchTerm}"`;
        }

        linkGroups.push({documents, header});
      }
      else if (companyKey) {
        message = `there are no results for '${searchTerm}' in ${companyMap[companyKey]}`;
      }
      else {
        message = `there are no results for '${searchTerm}'`;
      }
    }
    else {
      var highlightedDocuments = await lib.api.get('documents', {
        where: {isHighlighted: 1, visibleInTradeSite: 1},
        order: [{field: 'rank', direction: 'asc'}, {field: 'id', direction: 'desc'}]
      });

      linkGroups.push({header: `What's new and most important to know first`, documents: highlightedDocuments, key: 'highlights'});

      // var options = {
      //   where: {isHighlighted: 0, visibleInTradeSite: 1},
      //   order: [{field: 'rank', direction: 'asc'}, {field: 'id', direction: 'desc'}]
      // };

      // if (showAllDocuments) {
      //   header = 'all documents';
      // }
      // else {
      //   options.page = {count: 5, number: 1};

      //   header = (
      //     <div className='recent-documents-header'>
      //       <>recent</>
      //       <div className='show-all-button' onClick={this.props.toggleShowAllDocuments}>show all <span>&rarr;</span></div>
      //     </div>
      //   )
      // }

      // var otherDocuments = await lib.api.get('documents', options);

      // linkGroups.push({header, documents: otherDocuments});

      var otherDocuments = await lib.api.get('documents', {
        where: {isHighlighted: 0, visibleInTradeSite: 1},
        order: [{field: 'rank', direction: 'asc'}, {field: 'id', direction: 'desc'}]
      });

      _.forEach(_.groupBy(otherDocuments, 'topic'), (topicGroup, topic) => {
        header = topicTitles[topic] || 'other';

        linkGroups.push({header, documents: topicGroup, key: topic === 'undefined' ? 'other' : topic});
      });

      linkGroups = _.orderBy(linkGroups, linkGroup => _.indexOf(topicsOrder, linkGroup.key), 'asc');
    }

    this.setState({linkGroups, currentSearchTerm: searchTerm, message, searchTermRequested: false});
  }

  reportSearchTerm = () => {
    this.setState({searchTermRequested: true});

    alert(`We're on it! We'll get that information for you shortly if we can and let you know if we aren't able to.`);

    this.props.reportSearchTerm({searchTerm: this.props.requestedSearchTerm, userRequested: true});
  }

  get searchTerm() {
    return _.get(queryString.parse(window.location.search), 'searchTerm');
  }

  handleKeyPress = ({event}) => {
    if (lib.event.keyPressed(event, 'enter')) this.reportSearchTerm();
  }

  get isMobile () {
    return window.screen.width <= 500;
  }

  render() {
    var {setTopLevelDocument, setRequestedSearchTerm, requestedSearchTerm, match} = this.props;
    var {searchTerm, reportSearchTerm, handleKeyPress} = this;
    var {linkGroups, searchTermRequested} = this.state;

    var noSearchResults = _.isEmpty(_.get(linkGroups[0], 'documents'));

    var message = _.get(this.props, 'message', this.state.message);

    return (
      <div className='index-page page'>
        {match.path === '/' && <InterstitialPage/>}
        <div className='links-container'>
          {message && <div className='index-message'>{message}</div>}
          {_.map(linkGroups, ({header, documents}) => (
            <div className='link-group' key={header}>
              <div className='link-group-header'>{header}</div>
              <div className='link-group-documents'>
                {_.map(documents, document => (
                  <div className='link-group-document' key={document.id}>
                    <BrandIcons {...{document}}/>
                    <Link
                      className='link-group-document-title'
                      onClick={() => setTopLevelDocument({document, cameFromIndex: true})}
                      to={`/${document.uri ? document.uri : `documents/${document.id}`}${searchTerm ? `?searchTerm=${searchTerm}` : ''}`}
                    >{document.title}</Link>
                    {!_.isEmpty(document.documentSubtopics) && document.type !== 'slideshow' && <div className='link-group-document-subtopics'>
                      {_.map(_.slice(document.documentSubtopics, 0, 3), ({id: subtopicId, title, body}) => (
                        (title.length > 1 || body) && (
                          <Link
                            key={subtopicId}
                            className='link-group-subtopic'
                            onClick={() => setTopLevelDocument({document, cameFromIndex: true})}
                            to={`/documents/${document.id}/subtopics/${subtopicId}${searchTerm ? `?searchTerm=${searchTerm}` : ''}`}
                          >
                            <div className='subtopic-text-container'>
                              {title.length > 1 && <Link
                                className='subtopic-title'
                                onClick={() => setTopLevelDocument({document, cameFromIndex: true})}
                                to={`/documents/${document.id}/subtopics/${subtopicId}${searchTerm ? `?searchTerm=${searchTerm}` : ''}`}
                              >{title}</Link>}
                              {_.includes(['topic', 'fullscreenMedia'], document.type) && body && (
                                <div {...className(['subtopic-body', !_.includes(title, '?') && 'truncated-body'])}>
                                  <BodyText text={body}/>{/*_.includes(title, '?') ? body :  _.truncate(body, {length: 80})}/>*/}
                                </div>
                              )}
                            </div>
                          </Link>
                        )
                      ))}
                    </div>}
                  </div>
                ))}
              </div>
            </div>
          ))}
          {searchTerm && !searchTermRequested && <div className='searchterm-form'>
            <div className='searchterm-form-text'>
              {!noSearchResults && <div>don't see what you were looking for?</div>}
              <div>if you would like to write a few words here and tell us what you would like to know, we'll get back to you with that information</div>
            </div>
            <Form method='post'>
              <Form.TextInput
                value={requestedSearchTerm}
                className='searchterm-form-input'
                on={{input: setRequestedSearchTerm, keyDown: handleKeyPress}}
              />
            </Form>
            <div className='searchterm-form-submit' onClick={reportSearchTerm}>submit <span>&rarr;</span></div>
          </div>}
        </div>
      </div>
    );
  }
}

export default IndexPage;
