import _ from 'lodash';

//TODO remove
if (!window.lib) require('henrybuilt-js-library');

var lib = window.lib;

_.assign(lib, {
  identifyCompanyInSearchTerm: ({searchTerm, shouldExtract}) => {
    var identifiedCompanyKey;

    var companyAliases = {
      hb: ['henrybuilt systems', 'henrybuiltsystems', 'henry built', 'henrybuilt'],
      vp: ['space theory', 'spacetheory'],
      po: ['primary objects', 'primaryobjects'],
      sf: ['symbolic frameworks', 'symbolicframeworks']
    };

    _.forEach(['sf', 'po', 'vp', 'hb'], companyKey => {
      _.forEach(companyAliases[companyKey], alias => {
        if (_.includes(searchTerm, alias) && _.trim(searchTerm) !== alias) {
          if (shouldExtract) searchTerm = _.replace(searchTerm, alias, '');

          identifiedCompanyKey = companyKey;

          return false;
        }
      });
    });

    return {searchTerm, identifiedCompanyKey};
  }
});

export default lib;
