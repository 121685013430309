import {_, Component, React, className} from '@symbolic/react-app'; //eslint-disable-line
import MediumMetaData from '../medium-metadata/medium-metadata';
import './overlay-medium.scss';

var OverlayMedium = ({medium, onClick}) => (
  <div className='overlay-background' onClick={onClick}>
    {console.log(medium)}
    {medium.subjectType !== 'reference-video' ? (
      <div className='overlay-medium-wrapper'>
        <img className='overlay-medium' src={medium.url} alt={medium.title}/>
        {medium.title && <div className='medium-title'>{medium.title}</div>}
        {_.some(['caption', 'credit', 'city', 'state'], key => medium[key]) && <MediumMetaData {...medium}/>}
      </div>
    ) : (
      <div className='overlay-medium-wrapper'>
        <video className='overlay-video reference-media-video-player' muted autoPlay loop playsInline preload='none'>
          <source type='video/mp4' src={medium.url}/>
        </video>
        {_.some(['caption', 'credit', 'city', 'state'], key => medium[key]) && <MediumMetaData {...medium}/>}
      </div>
    )}
  </div>
);

export default OverlayMedium;
