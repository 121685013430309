import {_, Component, React, className} from '@symbolic/react-app'; //eslint-disable-line
import ShareButton from 'components/share-link-button/share-link-button';
import ReferenceIcons from 'components/reference-icons/reference-icons';
import BrandIcons from '../../../brand-icons/brand-icons';
import BodyText from 'components/body-text';
import './fullscreen-media-document.scss';

class FullscreenMediaDocument extends Component {
  state = {}

  componentDidMount() {
    this.scrollToSubtopic();
  }

  componentDidUpdate(prevProps) {
    if (this.props.subtopicId && this.props.subtopicId !== prevProps.subtopicId) {
      this.scrollToSubtopic();
    }
  }

  scrollToSubtopic() {
    var {subtopicId} = this.props;

    if (subtopicId) {
      var scrollChild = document.getElementById(`subtopic-${subtopicId}`);

      if (scrollChild) scrollChild.scrollIntoView()
    }
  }

  render() {
    var {document, allowSharing, match} = this.props;
    var {title, documentSubtopics} = document;
    var {setClickedMedium, setHoveredMedium} = this.props;

    return (
      <div className='fullscreen-media-document'>
        <div className='fullscreen-media-title'>{title}</div>
        <BrandIcons {...{document}}/>
        {allowSharing && <ShareButton {...{match}}/>}
        {document.fileUrl && <a className='full-document-link' href={document.fileUrl} target="_blank" rel="noopener noreferrer">view as pdf</a>}
        <div className='fullscreen-media-container'>
          {_.map(documentSubtopics, ({id, media, title, body}) => {
            var medium = _.find(media, medium => !_.includes(medium.subjectType, 'reference')) || {};
            var referenceMedia = _.filter(media, medium => _.includes(medium.subjectType, 'reference'));

            return (
              <div className='fullscreen-media-subtopic' id={`subtopic-${id}`}>
                {title && <div className='subtopic-title'>{title}</div>}
                {body && <div className='subtopic-body'><BodyText text={body}/></div>}
                {medium.url && (medium.type === 'video' ? (
                  <div className='subtopic-medium video'>
                    <video className='subtopic-medium slideshow-video-player' controls preload='none'>
                      <source type='video/mp4' src={medium.url}/>
                    </video>
                  </div>
                ) : (
                  <div className='subtopic-medium image' style={{backgroundImage: `url(${medium.url})`}}><img src={medium.url} alt=''/></div>
                ))}
                {!_.isEmpty(referenceMedia) && <ReferenceIcons {...{setClickedMedium, setHoveredMedium, referenceMedia}}/>}
              </div>
            )
          })}
        </div>
      </div>
    );
  }
}

export default FullscreenMediaDocument;
