var menuItems = [
  {companyKey: 'hb', title: 'Henrybuilt Systems', urls: [
    {title: 'Overview', documentId: '1051'},
    {title: 'Private Portfolio', url: 'https://henrybuilt.com/page/private-site'},
    {title: 'Frequently Asked Questions', documentId: '967'},
    {title: 'Public Website', url: 'https://henrybuilt.com'}
  ]},
  {companyKey: 'vp', title: 'Space Theory', urls: [
    {title: 'Overview', documentId: '1052'},
    {title: 'Frequently Asked Questions', documentId: '1041'},
    {title: 'Public Website', url: 'https://spacetheory.com'},
  ]},
  {companyKey: 'po', title: 'Primary Objects', urls: [
    {title: 'Overview', documentId: '1053'},
    {title: 'Public Website', url: 'https://objects.henrybuilt.com'},
  ]},
  {companyKey: 'sf', title: 'Symbolic Frameworks', urls: [
    {title: 'About', documentId: '1050'}
  ]}
];

export default menuItems;
