import {_, Component, React, className} from '@symbolic/react-app'; //eslint-disable-line
import {Form} from 'henrybuilt-react-library';
import queryString from 'query-string';
import {Link} from 'react-router-dom';
import menuItems from './menu-items';
import lib from 'components';
import './sidebar.scss';

class Sidebar extends Component {
  state = {
    searchTerm: '',
    documents: null
  }

  constructor(props) {
    super (props);

    this.debouncedSearch = _.debounce(this.search, 500);
  }

  componentDidUpdate(prevProps) {
    var urlChanged = prevProps.match.path !== this.props.match.path;
    var documentChanged = _.get(prevProps.document, 'id') !== _.get(this.props.document, 'id');
    var onIndex = _.includes(['/', '/index', '*'], this.props.match.path);
    var onSidebarLoadingOpenPage = _.get(this.props.document, 'type') === 'topic' || _.get(this.props.document, 'type') === undefined;
    var {isMobile} = this;

    if (!this.companyKeyChanged) this.companyKeyChanged = prevProps.companyKey !== this.props.companyKey;

    if (this.companyKeyChanged) setTimeout(() => this.companyKeyChanged = false, 500);

    if ((urlChanged || documentChanged) && !this.props.usingSharedLink) {
      if (!onIndex && (!onSidebarLoadingOpenPage || isMobile)) {
        this.props.toggleSidebar({sidebarOpen: false});
      }
      else if ((onIndex && !(isMobile && this.companyKeyChanged)) || !(this.props.cameFromIndex || isMobile)) {
        this.props.toggleSidebar({sidebarOpen: true});
      }
    }

    if ((urlChanged || !prevProps.sidebarOpen) && this.props.sidebarOpen) this.focus();

    if (urlChanged) this.setState({searchTerm: this.searchTerm});
  }

  componentDidMount = async () => {
    if (this.props.sidebarOpen) this.focus();

    if (this.searchTerm) this.setState({searchTerm: this.searchTerm});

    var documents = await lib.api.get('documents', {where: {id: [967, 1041, 1050, 1051, 1052, 1053]}});

    this.setState({documents});
  }

  focus = () => document.querySelector('.search-input-wrapper input').focus();

  handleSearchInput = ({value: searchTerm}) => {
    this.setState({searchTerm}, () => {
      if (!this.isMobile) this.debouncedSearch();
    });
  }

  search = () => {
    var {searchTerm} = this.state;

    this.props.setRequestedSearchTerm({value: searchTerm});

    if (searchTerm.length > 1) this.props.reportSearchTerm({searchTerm, userRequested: false});

    var {searchTerm, identifiedCompanyKey} = lib.identifyCompanyInSearchTerm({searchTerm, extract: false}); //eslint-disable-line

    if (identifiedCompanyKey && !(this.props.companyKey === identifiedCompanyKey)) this.props.setCompanyKey({companyKey: identifiedCompanyKey});

    var query = searchTerm ? `?searchTerm=${_.trim(searchTerm)}` : '';

    this.props.history.push(`/index${query}`);

    if (this.isMobile) _.delay(() => this.props.toggleSidebar({sidebarOpen: false}), 100);
  }

  handleKeyPress = ({event}) => {
    if (lib.event.keyPressed(event, 'enter')) this.search();
  }

  handleAllDocumentsClicked = () => {
    this.props.toggleShowAllDocuments({});

    if (this.isMobile) this.props.toggleSidebar({});
  }

  setTopLevelDocument = ({documentId}) => {
    this.props.setTopLevelDocument({document: _.find(this.state.documents, {id: documentId})})
  }

  get isMobile () {
    return window.screen.width <= 500;
  }

  get searchTerm () {
    return _.get(queryString.parse(window.location.search), 'searchTerm');
  }

  render() {
    var {handleSearchInput, search, handleKeyPress, focus, isMobile, searchTerm, handleAllDocumentsClicked, setTopLevelDocument} = this;
    var {setCompanyKey, companyKey: selectedCompanyKey, sidebarOpen, toggleSidebar, usingSharedLink} = this.props;

    return (
      <div {...className(['sidebar', sidebarOpen && !usingSharedLink && 'is-open'])}>
        {!usingSharedLink && <div className='sidebar-toggle-button-wrapper'>
          <img
            alt=''
            onClick={toggleSidebar}
            className='sidebar-toggle-button'
            src={sidebarOpen ? 'https://henrybuilt-cdn.s3-us-west-2.amazonaws.com/trade-site/icons/close.png' : 'https://henrybuilt-cdn.s3-us-west-2.amazonaws.com/trade-site/icons/menu.png'}
          />
        </div>}
      <div className='blinking-dot-container' onClick={toggleSidebar}><BlinkingDot/></div>
      <div {...className(['closed-sidebar', !sidebarOpen && 'visible', usingSharedLink && 'interaction-disabled'])}>
        <div className='company-buttons'>
          {_.map(['hb', 'vp', 'po', 'sf'], companyKey => (
            <Link
              to={`/index${searchTerm ? `?searchTerm=${searchTerm}` : ''}`}
              key={companyKey}
              onClick={() => setCompanyKey({companyKey})}
              {...className(['company-button', companyKey === selectedCompanyKey && 'selected'])}
            >
              {companyKey === 'vp' ? 'st' : companyKey}
            </Link>
          ))}
        </div>
      </div>
      <div {...className(['open-sidebar', sidebarOpen && 'visible'])}>
        <div className='open-sidebar-content'>
          <div className='chat-container'>
            <div className='chat-message'>hello.<br/> what would you like to know?</div>
          </div>
          <div className='search-bar'>
            <Form method='post'>
              <Form.TextInput
                autofocus='true'
                value={this.state.searchTerm || ''}
                className='search-input-wrapper'
                on={{input: handleSearchInput, keyDown: handleKeyPress}}
              />
              <div className='search-buttons'>
                <div
                  onClick={() => {
                    this.setState({searchTerm: ''}, () => {
                      if (!isMobile) search();
                    });

                    focus();
                  }}
                  {...className(['clear-search-button-wrapper', (this.state.searchTerm) && 'visible'])}
                >
                  <img className='clear-search-button' alt='' src='https://henrybuilt-cdn.s3-us-west-2.amazonaws.com/trade-site/icons/close.png'/>
                </div>
                <div
                  onClick={search}
                  {...className(['search-submit-button-wrapper', (this.state.searchTerm) && 'visible'])}
                >
                  <img
                    alt='submit'
                    className='search-submit-button'
                    src='https://henrybuilt-cdn.s3-us-west-2.amazonaws.com/images/icons/footer-arrows/arrow-right.png'
                  />
                </div>
              </div>
            </Form>
          </div>
          <div className='company-menu'>
            {_.map(menuItems, ({companyKey, title, urls}) => (
              <div className='company-menu-container' key={title}>
                <div
                  key={companyKey}
                  onClick={() => setCompanyKey({companyKey})}
                  {...className(['company-menu-button', companyKey === selectedCompanyKey && 'selected'])}
                >
                  <div className='company-menu-button-text'>{title}</div>
                </div>
                <div {...className(['company-links-container', companyKey === selectedCompanyKey && 'visible'])}>
                  {_.map(urls, ({title, url, documentId}) => url ? (
                    <a key={title} href={url} target='_blank' rel='noopener noreferrer' className='company-menu-link'>{title}</a>
                  ) : (
                    <Link
                      className='company-menu-link'
                      to={`/documents/${documentId}`}
                      onClick={() => setTopLevelDocument({documentId})}
                    >{title}</Link>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className='sidebar-menu-links'>
            {/* <Link to='/' onClick={handleAllDocumentsClicked}>all documents</Link> */}
            <a href="https://www.untappedjournal.com" target="_blank" rel="noopener noreferrer">Untapped: Our New Publication</a>
            <a href="https://www.henrybuilt.com/page/contact" target="_blank" rel="noopener noreferrer">Contact Us</a>
            <a href='https://henrybuilt.com/logout' className='logout-link'>log out</a>
            <a className="sf-credit" href="https://www.symbolicframeworks.com" target="_blank" rel="noopener noreferrer"> Site by Symbolic Frameworks</a>
          </div>
        </div>
      </div>
    </div>
    );
  }
};


export default Sidebar;

class BlinkingDot extends Component {
  state = {visible: true}

  componentDidMount() {
    this.blink = setInterval(this.toggleVisible, 650*3);
  }

  componentWillUnmount() {
    clearInterval(this.blink);
  }

  toggleVisible = () => this.setState(oldState => ({visible: !oldState.visible}))

  render() {
    var {visible} = this.state;

    return (
      <div {...className(['blinking-dot', visible && 'visible'])}/>
    );
  }
}
