import {_, Component, React, className} from '@symbolic/react-app'; //eslint-disable-line
import queryString from 'query-string';
import {Link} from 'react-router-dom';
import './header.scss';

class Header extends Component {
  state = {tableOfContentsVisible: false}

  toggleTableOfContents = ({tableOfContentsVisible}) => {
    this.setState(oldState => ({
      tableOfContentsVisible: tableOfContentsVisible === undefined ? !oldState.tableOfContentsVisible : tableOfContentsVisible
    }));
  }

  render() {
    var {document, setCompanyKey, usingSharedLink} = this.props;

    if (document) {
      var subtopicCount = _.get(document.documentSubtopics, 'length', 0);
      var showTableOfContents = _.includes(['topic', 'fullscreenMedia'], document.type) && subtopicCount > 1;
    }
    else {
      showTableOfContents = 0;
    }

    var searchTerm = _.get(queryString.parse(window.location.search), 'searchTerm');
    var searchTermQuery = searchTerm ? `?searchTerm=${searchTerm}` : '';
    var accessToken = _.get(queryString.parse(window.location.search), 'accessToken');
    var accessTokenQuery = accessToken ? `?accessToken=${accessToken}` : '';
    var {tableOfContentsVisible} = this.state;
    var {toggleTableOfContents} = this;

    return (
      <div className='main-header'>
        <div className='back-button-container'>
          {!usingSharedLink && <Link className='back-button-wrapper' to={`/`} onClick={() => setCompanyKey({companyKey: null})}>
            <img className='back-button-image' src='https://henrybuilt-cdn.s3-us-west-2.amazonaws.com/trade-site/icons/home.png' alt=''/>
          </Link>}
        </div>
        {showTableOfContents && _.some(document.documentSubtopics, 'title') && <div className='table-of-contents-container'>
          <div {...className(['toc-overlay', tableOfContentsVisible && 'is-visible'])} >
            <div className='toc-close-button-wrapper'>
              <img src='https://henrybuilt-cdn.s3-us-west-2.amazonaws.com/trade-site/icons/close.png' alt=''/>
            </div>
          </div>
          <div
            {...className(['table-of-contents', tableOfContentsVisible && 'is-visible', subtopicCount > 20 && 'many-links'])}
            onMouseLeave={() => this.setState({tableOfContentsVisible: false})}
            onMouseEnter={() => this.setState({tableOfContentsVisible: true})}
            onClick={toggleTableOfContents}
          >
            <div className='toc-wrapper'>
              <div className='toc-button'>
                <div className='toc-button-arrow-wrapper'>
                  <img src='https://henrybuilt-cdn.s3-us-west-2.amazonaws.com/trade-site/icons/down-arrow.png' alt=''/>
                </div>
              </div>
              <div className='toc-dropdown'>
                <div className='toc-links' onClick={toggleTableOfContents}>
                  {_.map(document.documentSubtopics, ({title, id}) => (
                    title && <Link to={`/documents/${document.id}/subtopics/${id}${searchTermQuery}${accessTokenQuery}`} onClick={() => this.setState({tableOfContentsVisible: false})} key={id}>{title}</Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>}
        <Link {...className(['main-title', usingSharedLink && usingSharedLink && 'interaction-disabled'])} to={`/`} onClick={() => setCompanyKey({companyKey: null})}>
          <div className='open-door'>Open &nbsp;Door</div>
          <div className='colon'>:&nbsp;&nbsp;</div>
          <div className='henrybuilt long-title'>Henrybuilt &nbsp;Private &nbsp;Trade &nbsp;Site</div>
          <div className='henrybuilt short-title'>HB &nbsp;Private &nbsp;Trade &nbsp;Site</div>
        </Link>
      </div>
    )
  }
}

export default Header;
