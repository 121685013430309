import {_, Component, React, className} from '@symbolic/react-app'; //eslint-disable-line
import './medium-metadata.scss';

var MediumMetaData = ({caption, credit, city, state}) => (
  <div className='medium-metadata-background'>
    <div className='medium-metadata-text'>
      {caption && <div className='medium-metadata-caption'>{caption}</div>}
      {caption && (credit || city || state) && <><br /><br /></>}
      <div className='medium-metadata-credit-location'>
        {credit && <div className='medium-metadata-credit'>With {credit}</div>}
        {credit && city && state && <div className='divider'>&nbsp; | &nbsp;</div>}
        {city && state && <div className='medium-metadata-location'>{city}, {state}</div>}
      </div>
    </div>
  </div>
);

export default MediumMetaData;
