import {_, Component, React, className} from '@symbolic/react-app'; //eslint-disable-line
import ReferenceIcons from 'components/reference-icons/reference-icons';
import ShareButton from 'components/share-link-button/share-link-button';
import BrandIcons from '../../../brand-icons/brand-icons';
import BodyText from 'components/body-text';
import './subtopic-document.scss';

class SubtopicDocument extends Component {
  state = {}

  componentDidMount() {
    this.scrollToSubtopic();
  }

  componentDidUpdate(prevProps) {
    if (this.props.subtopicId && this.props.subtopicId !== prevProps.subtopicId) {
      this.scrollToSubtopic();
    }
  }

  scrollToSubtopic() {
    var {subtopicId} = this.props;

    if (subtopicId) {
      var scrollChild = document.getElementById(`subtopic-${subtopicId}`);

      if (scrollChild) scrollChild.scrollIntoView()
    }
  }

  render() {
    var {document, setClickedMedium, setHoveredMedium, allowSharing, match} = this.props;
    var {title, author, documentSubtopics} = document;

    return (
      <div className='subtopic-document'>
        <div className='subtopic-document-wrapper'>
          <div className='subtopic-document-title'>{title}</div>
          <BrandIcons {...{document}}/>
          {allowSharing && <ShareButton {...{match}}/>}
          {author && <div className='subtitle'>by {author}</div>}
          <div className='document-content'>
            <div className='subtopics-container'>
              {_.map(documentSubtopics, subtopic => {
                var {title, body, id, media} = subtopic;
                var referenceMedia = _.filter(media, ({subjectType}) => _.includes(subjectType, 'reference'));
                var hasBrandIcons = _.some(['hb', 'vp', 'po', 'sf'], key => subtopic[`${key}Compatible`]);
                var classes = ['subtopic-container', title && 'has-title', body && 'has-body', hasBrandIcons && 'has-brand-icons'];

                return (
                  <div {...className(classes)} key={id} id={`subtopic-${id}`}>
                    {title && <div className='subtopic-title'>{title}</div>}
                    {hasBrandIcons && <BrandIcons document={subtopic}/>}
                    {body && <div className='subtopic-body'><BodyText text={body}/></div>}
                    {!_.isEmpty(referenceMedia) && <ReferenceIcons {...{setClickedMedium, setHoveredMedium, referenceMedia, type: 'subtopic-document'}}/>}
                  </div>
              )})}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SubtopicDocument;
