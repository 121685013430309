import {_, Component, React, connect, className} from '@symbolic/react-app'; //eslint-disable-line
import DocumentTitle from 'react-document-title';
import SubtopicDocument from './subtopic-document/subtopic-document';
import SlideshowDocument from './slideshow-document/slideshow-document';
import FullscreenMediaDocument from './fullscreen-media-document/fullscreen-media-document';
import OverlayMedium from 'components/overlay-medium/overlay-medium';
import {Redirect} from 'react-router-dom';
import queryString from 'query-string';
import lib from 'components';
import './document-page.scss';

class DocumentPage extends Component {
  static documentComponentMap = {
    file: null,
    topic: SubtopicDocument,
    slideshow: SlideshowDocument,
    fullscreenMedia: FullscreenMediaDocument,
    html: null
  }

  state = {
    document: {},
    clickedMedium: null,
    hoveredMedium: null,
    isLoading: true
  }

  constructor(props) {
    super(props);

    this.setHoveredMedium = _.debounce(this.setHoveredMedium.bind(this), 20);
  }

  componentDidMount() {
    this.setDocument();
  }

  componentDidUpdate() {
    if (_.get(this.props, `match.params.id`) != _.get(this.state.document, 'id')) { //eslint-disable-line
      this.setDocument();
    }
    else if (_.get(this.props, `match.params.subtopicId`) != _.get(this.state, 'subtopicId')) { //eslint-disable-line
      this.setState({subtopicId: _.get(this.props, `match.params.subtopicId`)});
    }
  }

  componentWillUnmount() {
    this.props.setTopLevelDocument({document: null, cameFromIndex: false});
  }

  setDocument = async () => {
    var documentId = _.get(this.props, `match.params.id`);
    var subtopicId = _.get(this.props, `match.params.subtopicId`);
    var accessToken = _.get(queryString.parse(window.location.search), 'accessToken');

    if (documentId) {
      try {
        var {data} = await lib.api.request({
          uri: 'get-document',
          useActualErrorMessages: true,
          body: {accessToken, documentId}
        });

        var {document} = data;

        if (document.type === 'file') {
          window.location.replace(document.fileUrl);
        }
        else {
          this.setState({document, subtopicId, isLoading: false});

          this.props.setTopLevelDocument({document});
        }
      }
      catch (error) {
        this.setState({isLoading: false});
      }
    }
  }

  setClickedMedium = ({medium}) => {
    if (_.get(this.state.clickedMedium, 'id') === _.get(medium, 'id')) {
      this.setState({clickedMedium: null, hoveredMedium: null});
    }
    else {
      this.setState({clickedMedium: medium});
    }
  }

  setHoveredMedium = ({medium}) => this.setState({hoveredMedium: medium});

  render() {
    var {clickedMedium, hoveredMedium, document, subtopicId, isLoading} = this.state;
    var {setClickedMedium, setHoveredMedium} = this;
    var {session, match} = this.props;

    var user = _.get(session, 'user', {});
    var allowSharing = _.includes(['admin', 'employee'], user.authGroup);

    subtopicId = subtopicId && parseInt(subtopicId);

    var DocumentComponent = DocumentPage.documentComponentMap[document.type];
    var showingOverlay = clickedMedium || hoveredMedium;

    return (
      (isLoading || !_.isEmpty(document)) ? (
        <DocumentTitle title={document.title ? `${document.title} | Open Door` : 'Henrybuilt | Open Door'}>
          <div {...className(['document-page', 'page', showingOverlay && 'showing-overlay'])} id='document-page'>
            {DocumentComponent && <DocumentComponent {...{document, setClickedMedium, setHoveredMedium, subtopicId, allowSharing, match}}/>}
            {(showingOverlay) &&
              <OverlayMedium medium={clickedMedium || hoveredMedium} onClick={() => setClickedMedium({medium: null})}/>}
          </div>
        </DocumentTitle>
      ) : (
        <Redirect to={{pathname: '/page-not-found'}}/>
      )
    )
  }
}

export default connect({mapState: ['session']})(DocumentPage);
