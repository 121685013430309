import {_, Component, React, className} from '@symbolic/react-app'; //eslint-disable-line
import './brand-icons.scss';

var BrandIcons = ({document}) => (
  <div className='brand-icons'>
    {_.map({hb: '#000000', vp: '#7ba6c1', po: '#c4ae4e', sf: '#8b697f'}, (color, companyKey) => (
      <div
        className={`brand-icon ${document[`${companyKey}Compatible`] ? 'active' : ''}`}
        key={companyKey}
        style={{color, borderColor: color}}
      >{companyKey === 'vp' ? 'st' : companyKey}</div>
    ))}
  </div>
);

export default BrandIcons;
