import {_, Component, React, connect} from '@henrybuilt/react-app'; //eslint-disable-line
import {Route, Switch} from 'react-router-dom';
import lib from 'components';
import queryString from 'query-string';
import Header from 'components/header/header';
import Sidebar from 'components/sidebar/sidebar';
import IndexPage from 'components/pages/index/index-page';
import DocumentPage from 'components/pages/document/document-page';
import ReactGA from 'react-ga';
import './app.scss';

class App extends Component {
  static permissionDeniedMessages = {
    loggedOut: <>Please <a href={`https://apps.henrybuilt.com/login?redirect_url=${window.location.href}`}>log in</a> to access the Open Door Trade Site. <br /><br />If you don't have a trade account, you can register for one <a href='https://objects.henrybuilt.com/trade-registry'>here</a>.</>,
    notTrade: <>It looks like you have an account, but aren't registered as trade. <br /><br />You can register <a href='https://objects.henrybuilt.com/trade-registry'>here</a>.</>,
    invalidLink: <>This link is invalid or expired.</>
  }

  state = {
    sidebarOpen: this.sidebarShouldBeOpen,
    companyKey: null,
    searchTerm: null,
    document: null,
    cameFromIndex: false,
    showAllDocuments: false,
    requestedSearchTerm: '',
    permissionDeniedMessageKey: null,
    isLoading: true,
    usingSharedLink: false
  }

  componentDidMount() {
    this.checkForPermissions();
  }

  componentDidUpdate(prevProps) {
    if (this.props.session.isLoading !== prevProps.session.isLoading) {
      this.checkForPermissions();
    }
  }

  checkForPermissions = async () => {
    var isLoading = false;
    var {documentId, accessToken} = this;
    var permissionDeniedMessageKey, usingSharedLink;

    if (!this.props.session.isLoading) {
      if (!this.props.session.user || !_.includes(['employee', 'admin'], this.props.session.user.authGroup)) {
        ReactGA.initialize('UA-87807718-1');

        if (this.props.session.user) ReactGA.set({userId: this.props.session.user.id});
      }

      if (accessToken) {
        try {
          if (!documentId || !accessToken) throw new Error();

          await lib.api.request({
            uri: 'get-document',
            useActualErrorMessages: true,
            body: {accessToken, documentId}
          });

          if (!this.props.session.isLoggedIn) usingSharedLink = true;
        }
        catch (error) {
          permissionDeniedMessageKey = 'invalidLink';
        }
      }
      else if (!this.props.session.isLoggedIn) {
        permissionDeniedMessageKey = 'loggedOut';
      }
      else if (!(this.props.session.user.isTrade || this.props.session.user.level > 1)) {
        permissionDeniedMessageKey =  'notTrade';
      }
    }
    else {
      isLoading = true;
    }

    this.setState({permissionDeniedMessageKey, isLoading, usingSharedLink});
  }

  setCompanyKey = ({companyKey}) => {
    this.setState({companyKey: this.state.companyKey === companyKey ? null : companyKey, showAllDocuments: false});
  }

  toggleSidebar = ({sidebarOpen}) => {
    this.setState(oldState => ({sidebarOpen: sidebarOpen === undefined ? !oldState.sidebarOpen : sidebarOpen}));
  }

  setTopLevelDocument = ({document, cameFromIndex}) => {
    this.setState(oldState => ({document, cameFromIndex: cameFromIndex !== undefined ? cameFromIndex : oldState.cameFromIndex}));
  }

  toggleShowAllDocuments = ({showAllDocuments}) => {
    this.setState(oldState => ({showAllDocuments: showAllDocuments === undefined ? !oldState.showAllDocuments : showAllDocuments, companyKey: null}));
  }

  setRequestedSearchTerm = ({value: requestedSearchTerm}) => this.setState({requestedSearchTerm});

  reportSearchTerm = _.debounce(async ({searchTerm, userRequested}) => {
    var today = new Date();
    var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    var time = today.getHours() + ":" + today.getMinutes();

    await lib.api.request({
      uri: 'report-trade-search-term',
      useActualErrorMessages: true,
      body: {searchTerm, date, time, userRequested}
    });
  }, 1000);

  render() {
    var {companyKey, sidebarOpen, document, cameFromIndex, showAllDocuments, requestedSearchTerm, permissionDeniedMessageKey, isLoading, usingSharedLink} = this.state;
    var {setCompanyKey, toggleSidebar, reportSearchTerm, setTopLevelDocument, toggleShowAllDocuments, setRequestedSearchTerm} = this;
    var {match, history} = this.props;

    var indexProps = {companyKey, setTopLevelDocument, reportSearchTerm, toggleShowAllDocuments, showAllDocuments, setRequestedSearchTerm, requestedSearchTerm};

    return (
      <div className='app'>
        <Header {...{match, document, setCompanyKey, usingSharedLink}}/>
        <div className='lower-screen'>
          {permissionDeniedMessageKey || isLoading ? (
            !isLoading && <div className='permission-denied-message-container'>
              <div className='permission-denied-message'>
                For our Architect, Designer, and Contractor Partners
                <br /><br />
                <span style={{opacity: 0.5}}>
                  Open Door is a straight-shooting Q&A search-driven site built to answer your questions and provide the information you need to put our three brands to work for you in flexible ways.
                </span>
                <br /><br />
                {App.permissionDeniedMessages[permissionDeniedMessageKey]}
              </div>
            </div>
          ) : (
            <>
              <Sidebar {...{match, history, setCompanyKey, toggleSidebar, sidebarOpen, document, cameFromIndex, usingSharedLink, ...indexProps}}/>
              <div className='main-content'>
                <Switch>
                  <Route path='/' exact render={() => <IndexPage {...{...indexProps, match}}/>}/>
                  <Route path='/index' exact render={() => <IndexPage {...{...indexProps, match}}/>}/>
                  <Route path='/documents/:id/:slug?' exact render={() => <DocumentPage {...{match, setTopLevelDocument}}/>}/>
                  <Route
                    exact
                    render={() => <DocumentPage {...{match, ...indexProps}}/>}
                    path='/documents/:id/:slug?/subtopics/:subtopicId/:subtopicSlug?'
                  />
                  <Route render={() => <IndexPage
                    {...{...indexProps, match, message: 'page not found'}}
                  />}/>
                </Switch>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  get documentId () {
    return this.props.match.params.id;
  }

  get accessToken () {
    return _.get(queryString.parse(window.location.search), 'accessToken');
  }

  get sidebarShouldBeOpen () {
    var onIndex = _.includes(['/index', '/'], window.location.pathname);
    var isMobile = window.screen.width < 801;

    return onIndex && !isMobile;
  }
}

export default connect({mapState: ['session']})(App);
