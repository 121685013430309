import {_, Component, React, className} from '@symbolic/react-app'; //eslint-disable-line
import './reference-icons.scss';

var ReferenceIcons = ({referenceMedia, setClickedMedium, setHoveredMedium, type}) => (
  <div {...className(['reference-icons', `type-${type}`])}>
    {_.map(referenceMedia, medium => (
      _.includes(medium.subjectType, 'reference') && <div
        key={medium.id}
        onClick={() => setClickedMedium({medium})}
        onMouseEnter={() => setHoveredMedium({medium})}
        onMouseLeave={() => setHoveredMedium({medium: null})}
        {...className(['reference-icon', `type-${medium.subjectType}`])}
      >
        <img
          alt=''
          src={`https://henrybuilt-cdn.s3-us-west-2.amazonaws.com/trade-site/icons/${medium.subjectType}.png`}
        />
      </div>
    ))}
  </div>
);

export default ReferenceIcons;
