import React from 'react';
import ReactDOM from 'react-dom';
import App from 'components/app/app';
import DocumentTitle from 'react-document-title';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import {createStore, AppWrapper} from '@henrybuilt/react-app';

global.NODE_ENV = process.env.NODE_ENV;

var store = createStore();

ReactDOM.render((
  <AppWrapper store={store} appKey='trade' hideHeader>
    <DocumentTitle title='Henrybuilt | Open Door'></DocumentTitle>
    <Router>
      <Switch>
        <Route exact path={['/', '/index', '/contact', '/visit-us', '/documents/:id/:slug?', '/documents/:id/:slug?/subtopics/:subtopicId/:subtopicSlug?', '*']}  render={({match, history}) => (
          <App {...{match, history}}/>
        )}/>
      </Switch>
    </Router>
  </AppWrapper>
), document.getElementById('root'));

serviceWorker.unregister();
