import {_, Component, React, className} from '@henrybuilt/react-app'; //eslint-disable-line
import MediumMetaData from '../../medium-metadata/medium-metadata';
import Cookies from 'js-cookie';
import './interstitial-page.scss';

class InterstitialPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tick: 0,
      timer: null,
      shouldRedirect: false,
      interstitialMediumIndex: null
    };
  }

  static metadata = [
    // {credit: 'NICOLEHOLLIS + AUM Construction', city: 'Elk', state: 'California'},
    // {credit: 'Walker Smith-williams and Billy Erhard', city: 'Brooklyn', state: 'New York'},
    // {credit: 'Faulkner Architects + Rickenbach', city: 'Truckee', state: 'California'},
    // {credit: 'BoydDesign + Rowla Studios', city: 'Beverly Hills', state: 'California'},
    // {credit: 'Studio Cicetti Architecture', city: 'Los Angeles', state: 'California'},
    // {credit: 'Studio Cicetti Architecture', city: 'Los Angeles', state: 'California'},
    // {credit: 'Cecily Young & Moore Ruble Yudell + Jetton Construction', city: 'Beverly Hills', state: 'California'}
  ];

  componentDidMount() {
    var interstitialMediaNumber = 13;

    var interstitialMediumIndex = parseInt(Cookies.get('trade-site-interstitial-page-medium-index') || 0) % interstitialMediaNumber;

    var timer = setInterval(this.incrementTick, 10);

    this.setState({timer, interstitialMediumIndex});

    var newMediaSetIndex = (interstitialMediumIndex + 1) % interstitialMediaNumber;

    Cookies.set('trade-site-interstitial-page-medium-index', newMediaSetIndex);
  }

  componentWillUnmount() {
    clearInterval(this.state.timer);
  }

  incrementTick = () => this.setState({tick: this.state.tick + 1});

  handleClick = () => this.setState({shouldRedirect: true});

  render() {
    var {tick, shouldRedirect, interstitialMediumIndex} = this.state;
    var {metadata} = InterstitialPage;

    if (tick > 350) clearInterval(this.state.timer);

    return shouldRedirect || tick > 300 ? (
      <div/>
    ) : (
      <>
        <div {...className(['interstitial-page-background', tick < 150 && 'active'])}/>
        <div {...className(['interstitial-page', tick > 10 && tick < 200 && 'active'])} onClick={this.handleClick}>
          <img src={`https://henrybuilt-cdn.s3-us-west-2.amazonaws.com/trade-site/interstitial-page/interstitial-page-${interstitialMediumIndex}.jpg`} alt=''/>
          {_.some(metadata[interstitialMediumIndex]) && <MediumMetaData {...metadata[interstitialMediumIndex]}/>}
        </div>
      </>
    );
  }
}

export default InterstitialPage;
