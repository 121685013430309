import {_, Component, React, className} from '@symbolic/react-app'; //eslint-disable-line
import lib from 'components';
import './share-link-button.scss';

class ShareButton extends Component {
  state = {message: 'share'}

  createShareableLink = async () => {
    clearTimeout(this.reset);

    this.setState({message: 'creating link...'});

    var {match} = this.props;

    var response = await lib.api.request({
      uri: 'access-token/create',
      useActualErrorMessages: true,
      body: {resourceId: match.params.id}
    });

    var link = `${window.location.host}/documents/${match.params.id}?accessToken=${response.data.accessToken.token}`;

    await navigator.clipboard.writeText(link);

    alert(`Link copied to clipboard: ${link}`);

    this.setState({message: 'link copied'}, () => {
      this.reset = setTimeout(() => this.setState({message: 'share'}), 3000);
    });
  }

  render() {
    var {message} = this.state;

    return (
      <div className='shareable-link-button' onClick={this.createShareableLink}>
        <div className='share-link-button-message'>{message}</div>
      </div>
    );
  }
};

export default ShareButton;
